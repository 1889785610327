import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SuggestToolButton from './components/SuggestToolButton'; // Make sure to create this file


const Footer = () => {
  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gray-800 py-2 sm:py-4">
      <div className="max-w-4xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
        <p className="text-gray-400 text-xs sm:text-sm">© 2024 - IndieHackerToolsDirectory</p>
        <div className="flex items-center space-x-2 sm:space-x-4">
          <SuggestToolButton />
          <a
            href="https://theaihire.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 bg-purple-600 text-white text-xs sm:text-sm rounded-full hover:bg-purple-700 transition-colors duration-200"
          >
            Find Verticalised A.I
          </a>
          <a
            href="https://twitter.com/AntoineLevy27"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-1 sm:px-4 sm:py-2 bg-black text-white text-xs sm:text-sm rounded-full hover:bg-gray-700 transition-colors duration-200"
          >
            <svg className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
            </svg>
            Follow @AntoineLevy27
          </a>
        </div>
      </div>
    </footer>
  );
};


const stages = [
  {
    name: 'Ideate 💡',
    substages: [
      {
        name: 'Co-founder Matching',
        tools: [
          { name: 'Y Combinator Co-founder Matching', description: 'Co-founder matching platform', url: 'https://www.ycombinator.com/cofounder-matching' },
          { name: 'CoffeeSpace', description: 'Co-founder matching platform', url: 'https://www.coffeespace.com/' },
          { name: 'Tertle', description: 'Co-founder matching platform', url: 'https://tertle.io/' },
          { name: 'FounderIo', description: 'Co-founder matching platform', url: 'https://www.founderio.com/' },
        ]
      },
      {
        name: 'Ideation',
        tools: [
          { name: 'Indie Hackers', description: 'Indie Hacker community platform', url: 'https://www.indiehackers.com/' },
          { name: 'Build in public', description: 'X community for Indie Hackers', url: 'https://x.com/buildinpublic' },
        ]
      },
      {
        name: 'Research',
        tools: [
          { name: 'Google Trends', description: 'Search volumes and rends insights ool', url: 'https://trends.google.com/trends/' },
          { name: 'Ahref Keyword Research Tool', description: 'Keyword research tool', url: 'https://ahrefs.com/keyword-generator' },
          { name: 'SimilarWeb Traffic Checker', description: 'Website traffic analytics', url: 'https://lp.similarweb.com/competitive-analysis-brand/?utm_medium=ppc&utm_source=adwords&utm_campaign=dmng_search_brand_cross_both_uk&utm_id=21309765247&utm_content=699914175093&utm_term=similarweb&utm_network=g&utm_group=168282957088&utm_placement=&utm_matchtype=e&utm_adposition=&affiliate_id=similarweb&gad_source=1&gclid=Cj0KCQjwgL-3BhDnARIsAL6KZ6-KtZiQ5-0xNpBD_LBbjRhbPZCRheZTNquD56Xfz1kfE7m2KAfoBUQaAs77EALw_wcB' },
          { name: 'Buildpad', description: 'Idea validation platform', url: 'https://buildpad.io/' },
        ]
      },
    ],
  },
  {
    name: 'Build 🛠️',
    substages: [
      {
        name: 'Planning',
        tools: [
          { name: 'Trello', description: 'Project management tool', url: 'https://trello.com/' },
          { name: 'Asana', description: 'Work management platform', url: 'https://asana.com/' },
          { name: 'Notion', description: 'All-in-one workspace', url: 'https://www.notion.so/' },
        ]
      },

      {
        name: 'Prototyping',
        tools: [
          { name: 'Unbounce', description: 'Landing page building site', url: 'https://unbounce.com/' },
          { name: 'Carrd', description: 'Landing page building site', url: 'https://carrd.co/' },
          { name: 'Launchrock', description: 'Landing page building site', url: 'https://www.launchrock.com/' },
        ]
      },

      {
        name: 'Design',
        tools: [
          { name: 'Figma', description: 'Collaborative interface design tool', url: 'https://www.figma.com/' },
          { name: 'Adobe XD', description: 'UI/UX design software', url: 'https://adobexdplatform.com/' },
          { name: 'InVision', description: 'Digital product design platform', url: 'https://www.invisionapp.com/' },
        ]
      },


      {
        name: 'Development',
        tools: [
          { name: 'GitHub', description: 'Code hosting platform', url: 'https://github.com/' },
          { name: 'VS Code', description: 'Code editor', url: 'https://code.visualstudio.com/' },
          { name: 'Cursor', description: 'Code editor', url: 'https://www.cursor.com/' },
          { name: 'Vercel', description: 'Deployment and hosting platform', url: 'https://vercel.com/' },
        ]
      },
    ],
  },
  {
    name: 'Launch 🚀',
    substages: [
      {
        name: 'Marketing',
        tools: [
          { name: 'Mailchimp', description: 'Email marketing platform', url: 'https://mailchimp.com/' },
          { name: 'Hootsuite', description: 'Social media management', url: 'https://www.hootsuite.com/' },
          { name: 'Canva', description: 'Graphic design platform', url: 'https://www.canva.com/' },
        ]
      },
      {
        name: 'Analytics',
        tools: [
          { name: 'Google Analytics', description: 'Web analytics service', url: 'https://analytics.google.com/' },
          { name: 'Mixpanel', description: 'Product analytics', url: 'https://mixpanel.com/' },
          { name: 'Hotjar', description: 'Website heatmaps and user recordings', url: 'https://www.hotjar.com/' },
        ]
      },
      {
        name: 'Launch Platforms',
        tools: [
          { name: 'Product Hunt', description: 'Product launch platform', url: 'https://www.producthunt.com/' },
          { name: 'BetaList', description: 'MVP launch platform', url: 'https://betalist.com/' },
          { name: 'Microlaunch', description: 'Product/MVP launch platform', url: 'https://microlaunch.net/' },
        ]
      },
    ],
  }
];

const TabNavigation = ({ openStage, toggleStage }) => (
  <nav className="tab-navigation">
    <ul className="flex justify-center list-none p-0 m-0 bg-opacity-80 bg-gray-800 rounded-2xl overflow-hidden max-w-3xl mx-auto">
      {stages.map((stage, index) => (
        <li key={index} className="flex-1">
          <button
            className={`w-full py-3 px-4 text-center text-sm sm:text-base transition-all duration-300 ease-in-out ${
              openStage === index ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white'
            }`}
            onClick={() => toggleStage(index)}
          >
            {stage.name}
          </button>
        </li>
      ))}
    </ul>
  </nav>
);

const ExpandedContent = ({ stage, openSubstage, toggleSubstage }) => {
  return (
    <div className="mt-2 max-w-3xl mx-auto flex">
      <div className="w-1/3 pr-4">
        {stage.substages.map((substage, subIndex) => (
          <div key={subIndex} className="mb-2">
            <button
              className={`font-semibold text-left w-full py-2 px-3 rounded-lg transition-all duration-300 ease-in-out ${
                openSubstage === subIndex ? 'bg-gray-700 text-white' : 'text-gray-400 hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => toggleSubstage(subIndex)}
            >
              <span>{substage.name}</span>
              {openSubstage === subIndex ? <ChevronUp className="inline ml-2" size={16} /> : <ChevronDown className="inline ml-2" size={16} />}
            </button>
          </div>
        ))}
      </div>
      <div className="w-2/3 pl-4">
        {openSubstage !== null && (
          <ul className="space-y-4">
            {stage.substages[openSubstage].tools.map((tool, toolIndex) => (
              <li key={toolIndex} className="bg-gray-800 bg-opacity-50 p-4 rounded-lg">
                <a href={tool.url} className="text-blue-400 hover:underline text-lg">{tool.name}</a>
                <p className="text-gray-400 mt-2">{tool.description}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const IndieHackerToolsDirectory = () => {
  const [openStage, setOpenStage] = useState(0); // Set default open stage to 0 (Ideate)
  const [openSubstage, setOpenSubstage] = useState(0); // Set default open substage to 0 (Co-founder Matching)

  const toggleStage = (index) => {
    if (openStage === index) {
      setOpenStage(null);
      setOpenSubstage(null);
    } else {
      setOpenStage(index);
      setOpenSubstage(0); // Reset to the first substage when changing stages
    }
  };

  const toggleSubstage = (index) => {
    setOpenSubstage(openSubstage === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-gray-800 p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="mt-12 sm:mt-24 text-2xl sm:text-3xl font-bold mb-4 sm:mb-8 text-center text-gray-100">IndieHacker Tools Directory</h1>
        <p className="text-center mb-8 sm:mb-12 text-sm sm:text-base text-gray-300">
          A curated list of tools for indie hackers at every stage of their journey.
        </p>
        <div className="mb-6 sm:mb-12">
          <TabNavigation openStage={openStage} toggleStage={toggleStage} />
        </div>
        {openStage !== null && (
          <div className="mb-12 sm:mb-24">
            <ExpandedContent stage={stages[openStage]} openSubstage={openSubstage} toggleSubstage={toggleSubstage} />
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default IndieHackerToolsDirectory;